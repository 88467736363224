<template>
  <!-- 故障记录详情、故障记录处理  -->
  <Modal
      class="fault-detail-modal"
      size="big"
      :on-close-trigger="closeDialog"
  >
    <template v-slot:header>
      <h3>{{ getTitle }}</h3>
    </template>
    <template v-slot:body>
      <div class="add-dev-body">
        <ValueGroup name="设备编号" v-if="isAddMillage" type="input" v-model="inputValue.devicename"/>
        <ValueGroup name="设备编号" v-if="isUpdateMillage" :value="inputValue.devicename"/>

        <ValueGroup name="设备别名" v-if="isAddMillage" type="input" v-model="inputValue.nickname"/>
        <ValueGroup name="设备别名" v-if="isUpdateMillage" :value="inputValue.nickname"/>

        <ValueGroup name="纬度(latitude)" type="input" v-model="inputValue.latitude"/>
        <ValueGroup name="经度(longitude)" type="input" v-model="inputValue.longitude"/>
        <ValueGroup name="录像版本" type="input" v-model="inputValue.video_version"/>
        <ValueGroup name="设备版本" type="input" v-model="inputValue.body_version"/>
        <ValueGroup name="摄像头版本" type="input" v-model="inputValue.camera_version"/>
        <ValueGroup name="生产日期" type="input" v-model="inputValue.manuf_date"/>
        <ValueGroup name="设备位置" type="input" v-model="inputValue.position"/>
        <ValueGroup name="device_secret" type="input" :title="inputValue.devicesecret" v-model="inputValue.devicesecret"/>
        <ValueGroup name="product_key" type="input" v-model="inputValue.productkey"/>
        <ValueGroup name="product_name" type="input" v-model="inputValue.productname"/>
        <ValueGroup name="product_secret" type="input" v-model="inputValue.product_secret"/>
        <ValueGroup name="消费组ID" type="input" v-model="inputValue.consume_id"/>

        <ValueGroup name="低报警值" type="input" v-model="inputValue.low_alert"/>
        <ValueGroup name="高报警值" type="input" v-model="inputValue.high_alert"/>
        <ValueGroup name="采集周期" type="input" v-model="inputValue.acquire"/>
        <ValueGroup name="上传周期" type="input" v-model="inputValue.update"/>

        <ValueGroup name="施工天数（时间戳）" type="input" v-model="inputValue.construction"/>
        <ValueGroup name="震动报警值" type="input" v-model="inputValue.warnvib"/>
        <ValueGroup name="震动频率" type="input" v-model="inputValue.hz_vib"/>
        <ValueGroup name="震动组别" type="input" v-model="inputValue.group_vib"/>
      </div>
      <div>
        <el-select v-model="inputValue.username" placeholder="选择设备所属用户">
          <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.username"
              :value="item.username">
          </el-option>
        </el-select>
      </div>
      <div
          v-for="key in Object.keys(support)"
          :key="key"
          style="margin-bottom: 5px;margin-top: 20px;"
      >
        <el-switch
            v-model="support[key]"
            active-value="1"
            inactive-value="0"
            :inactive-text="keyToText[key]"
            active-color="#e6a23c">
        </el-switch>
      </div>

    </template>
    <template v-slot:footer>
      <div class="button-wrap">
        <div class="button prefer" v-on:click="process">
          <span>确定</span>
        </div>
      </div>
    </template>
  </Modal>

</template>

<script>
import Modal from "@/components/modals/modalComponents/Modal";
import ValueGroup from "@/components/ValueGroup";
import {mapActions} from "vuex";
import {getAllUser} from "@/api/apiHandler";
import {insertNewDevices, updateNewDevices} from "../../../api/apiHandler";

const titleEnum = {
  [ModalActionEnum.ADD_DEVICE_GROUND]: '添加埋地桩设备',
  [ModalActionEnum.UPDATE_DEVICE_GROUND]: '修改埋地桩设备',
};


const keyToText = {
  yeweizhuangtaiSupport: '液位状态展示',
  menjinzhuangtaiSupport: '门禁状态展示',
  wenduSupport: '温度展示',
  jinqikouyaliSupport: '进气口压力展示',
  chuqikouyaliSupport: '出气口压力展示',
  nongduSupport: '浓度展示',
};

export default {
  name: "AddDevice",
  components: {ValueGroup, Modal},
  props: ['modalType', 'deviceInfo'],
  data: function () {
    return {
      userList: [],
      support: {
        // 液位状态展示
        yeweizhuangtaiSupport: true,
        // 门禁状态
        menjinzhuangtaiSupport: true,
        // wenduSupport
        wenduSupport: true,
        jinqikouyaliSupport: true,
        chuqikouyaliSupport: true,
        nongduSupport: true,
      },
      inputValue: {
        devicename: '',
        nickname: '',
        latitude: '',
        longitude: '',
        video_version: '',
        body_version: '',
        camera_version: '',
        manuf_date: '',
        position: '',
        devicesecret: '',
        productkey: '',
        productname: '',
        product_secret: '',
        consume_id: '',
        username: '',
        low_alert: '',
        high_alert: '',
        acquire: '',
        update: '',
        construction: '',
        warnvib: '',
        hz_vib: '',
        group_vib: '',
      },
    }
  },
  created() {
    this.initUserList().then();
    if (this.support) {
      Object.keys({...this.support}).forEach(key => {
        this.support[key] = this.deviceInfo[key];
      });
    }
    Object.keys({...this.inputValue}).forEach(key => {
      this.inputValue[key] = this.deviceInfo[key];
    });
  },
  computed: {
    keyToText() {
      return keyToText;
    },
    getTitle() {
      return titleEnum[this.modalType] || '';
    },
    isAddMillage() {
      return this.modalType === ModalActionEnum.ADD_DEVICE_GROUND;
    },
    isUpdateMillage() {
      return this.modalType === ModalActionEnum.UPDATE_DEVICE_GROUND;
    },
  },
  methods: {
    ...mapActions({
      'showModal': 'showModal',
      'hideModal': 'hideModal',
    }),


    closeDialog() {
      this.$emit('closeDialog');
    },

    async initUserList() {
      const res = await getAllUser();
      if (!res.success) {
        return;
      }
      this.userList = res.detail;
    },

    async process() {
      let hasNull = false;
      delete this.inputValue.userid;
      Object.keys(this.inputValue).forEach((key) => {
        if (!this.inputValue[key]) {
          hasNull = true;
        }
      });
      if (hasNull) {
        await this.$store.dispatch('toast/showToast', {message: '不能有空值'})
        return;
      }
      Object.entries(this.support).forEach(([key, value]) => this.support[key] = Number(value));
      const req = {
        ...this.inputValue,
        ...this.support,
        // token: this.token,
        type: 'millage',
        token: 'a999',
      }

      let res;
      if (this.isAddMillage) {
        res = await insertNewDevices(req);
      } else if (this.isUpdateMillage) {
        res = await updateNewDevices(req);
      }

      if (!res) {
        await this.$store.dispatch('toast/showToast', {message: res || '服务异常'})
        return;
      }
      await this.$store.dispatch('toast/showToast', {message: '操作成功'})
      this.$vModal.close();
      this.$bus.$emit('refreshPage', {});
    },

  },
}
</script>

<style scoped lang="less">
@import "../../../styles/common";

.fault-detail-modal {
  .add-dev-body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .form-group {
      width: 25%;
      box-sizing: border-box;
      padding-right: 20px;
    }
  }

  .grid {
    display: grid;
    grid-template-areas:
          'a b b'
          'c c c'
          'd e e';
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 0.2rem;

    .area-address {
      grid-area: e;
    }

    .area-device-name {
      grid-area: b;
    }

    .area-reason {
      grid-area: c;
    }
  }

  .button-wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    padding: 0 @padding @padding;
    .button;
  }
}

</style>
