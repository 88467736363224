import {request} from "@/utils/http";
import {getLeftSupportDevice, getUserIdByName, loginUser} from "@/api/apiHandler";
import {eq} from 'lodash-es';
import RoutesConstant, {typeMap} from "@/vuex/constant/RoutesConstant";
import {isArray} from "lodash-es";
import Login from "@/pages/Login.vue";
import VectorInfoModal from "@/components/modals/modalComponents/VectorInfoModal";
import EventBus from "@/utils/eventBus";

const moduleUser = {
    state: () => ({
        userId: 1,
        username: "",
        isAdmin: 0,
        token: null,
        errorMessage: '',
        routes: RoutesConstant,
    }),
    mutations: {
        setUserInfo(state, {username, token, isAdmin}) {
            state.username = username;
            state.token = token;
            state.isAdmin = isAdmin;
            window.token = token;
        },
        setUserId(state, userId) {
            state.userId = userId;
        },
        setUserRoutes(state, data) {
            state.routes = data;
        },
        setErrorMessage(state, {errorMessage}) {
            state.errorMessage = errorMessage;
        }
    },
    getters: {
        getShowRoutes(state) {
            if (RoutesConstant[0].version !== state.routes[0].version) {
                state.routes = RoutesConstant;
            }
            return state.routes.filter((item) => item.show);
        },
        getShowRoutesType(state) {
            return state.routes.filter((item) => item.show).map(item => item.type);
        },
    },
    actions: {
        async getUserRoutes({state}) {
            const {routes} = state;
            const {success, detail} = await getLeftSupportDevice({ userId: state.userId });
            if (!success) {
                return;
            }
            //     "jx": 0,  井下
            //     "cz": 0,  手持
            //     "kl": 1,  开路
            //     "sc": 0,  手持
            //     "lcz": 0, 里程桩
            //     "ds": 0,  点式
            //     "yt": 0   云台
            // const {
            //     jx,
            //     cz,
            //     kl,
            //     sc,
            //     lcz,
            //     ds,
            //     yt,
            // } = detail
            Object.keys(detail).forEach((key) => {
                if (key === 'userId') {
                    return;
                }
                const pageType = typeMap[key];
                routes.find((item) => item.type === pageType).show = detail[key];
            });
            return routes;
        },
        login(context, {username, password, lingpai = '123'}) {
            // 进行网络请求
            loginUser({
                username,
                password,
                lingpai,
            }).then(data => {
                console.log('登录成功', data, username);
                const {success, msg} = data;
                if (success === true) {
                    EventBus.$emit('handleLoginDialog', true);
                    context.commit('setUserInfo', {
                        username: username,
                        token: data.token,
                        isAdmin: Number(data.detail.is_admin),
                    });
                    // 管理员登录需要标识
                    if (Number(data.detail.is_admin)) {
                        VectorInfoModal.close();
                        this.commit('updateAdminLogin', true);
                    }
                    context.commit('setUserId', data.detail.id);
                } else {
                    if (msg === '令牌错误') {
                        VectorInfoModal({
                            info: {username, password},
                            type: ModalActionEnum.LING_PAI,
                        });
                        return;
                    }
                    context.commit('setErrorMessage', {
                        errorMessage: '用户名或密码错误'
                    })
                }
            })

        },

        setErrorMessage(context, errorMessage) {
            context.commit('setErrorMessage', {
                errorMessage
            });
        }

    }
}

export default moduleUser;
