import {post} from "@/utils/axios"


export const getUserIdByName = (params) => {
    return post('/user/JikongSave/getUserId', params)
};

// 获取设备列表
export const getDeviceList = (params) => {
    return post('/user/JikongSave/getDeviceList', {...params})
}

// 获取开路设备列表
export const getOpenDeviceList = (params) => {
    return post('/user/Jikong/getKaiLuDeviceList', {...params})
}

// 井下设备是否展示液位字段
export const showsDeviceName = (params) => {
    return post('/user/Jikong/showsDeviceName', {...params})
}

// 获取故障列表
export const getFaultList = (params) => {
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            params[key] = '';
        }
    });
    return post('/user/Jikong/getDeviceFailure', {...params})
}

// 获取报警信息列表
export const getAlertList = (params) => {
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            params[key] = '';
        }
    });
    return post('/user/JikongSave/searchAlert', {...params})
}

// 获取历史报警信息
export const getHistoryList = (params) => {
    return post('/user/JikongAlert/getHistoryAlert', {...params})
}

// 根据用户ID获取全部事件列表
export const getEventById = (params) => {
    return post('/user/JikongEvent/getEventById', {...params})
}

// 根据用户ID和处理状态获取全部报警列表
export const getAlertListByType = (params) => {
    return post('/user/JikongAlert/getAllBjAndGz', {...params})
}

// 根据用户ID和处理状态获取全开路设备报警列表
export const getOpenAlertList = (params) => {
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            params[key] = '';
        }
    });
    return post('/user/Jikong/searchGYGW_openwarns', {...params})
}

// 根据用户ID和处理状态获取全开路设备故障列表
export const getOpenFaultList = (params) => {
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            params[key] = '';
        }
    });
    return post('/user/Jikong/searchGYGW_openfaults', {...params})
}


// 根据用户ID和处理状态获取全部报警列表
export const getAlertListAll = (params) => {
    return post('/user/JikongAlert/getAllBjAndGzNew', {...params})
}

// 删除开路设备的警报
export const deleteOpenALertInfo = (params) => {
    return post('/user/JikongSave/delAlertForKaiLu', {...params})
}

// 删除除开路设备的警报
export const deleteHandCarALertInfo = (params) => {
    return post('/user/JikongSave/delAlert', {...params})
}


// 删除开路设备的故障
export const deleteOpenFaultInfo = (params) => {
    return post('/user/JikongSave/delGuZhangForKaiLu', {...params})
}

// 删除井下设备的故障
export const delJinxiaGuzhang = (params) => {
    return post('/user/JikongSave/delJinxiaGuzhang', {...params})
}

// 删除井下设备的报警
export const delJinxiaAlert = (params) => {
    return post('/user/JikongSave/delJinxiaAlert', {...params})
}

// 删除井下设备的历史记录
export const delJinxiaLishi = (params) => {
    return post('/user/JikongSave/delJinxiaLishi', {...params})
}


// 删除除开路设备的故障
export const deleteHandCarFaultInfo = (params) => {
    return post('/user/JikongSave/delGuZhang', {...params})
}

// 根据eventId获取巡检路线
export const getTrackPoint = (params) => {
    return post('/user/getTrackPoint', {...params})
}


// 获取开路设备历史记录
export const getOpenHistoryList = (params) => {
    Object.keys(params).forEach((key) => {
        if (params[key] === null) {
            params[key] = '';
        }
    });
    return post('/user/Jikong/searchKailulishi', {...params})
}

// 获取开路设备报警详情
export const getOpenInfoAlert = (params) => {
    return post('/user/Jikong/getKaiLuLiShiXiangQingBj', {...params})
}

// 获取开路设备故障详情
export const getOpenInfoFault = (params) => {
    return post('/user/Jikong/getKaiLuLiShiXiangQingGz', {...params})
}

// 获取开路设备故障详情
export const updatePwd = (params) => {
    return post('/user/updateUserPassword', {...params})
}

// 处理车载的报警和故障
export const handleCarHandAlertWarn = (params) => {
    return post('/user/JikongSave/ResolveAlert', {...params})
}

// 处理开路的报警和故障

export const handleOpenFaults = (params) => {
    return post('/user/Jikong/resolveGYGW_openfaults', {...params})
}

export const handleOpenWarns = (params) => {
    return post('/user/Jikong/resolveGYGW_openwarns', {...params})
}

// 获取当前设备的所有巡检记录
export const getEventList = (params) => {
    return post('/user/Jikong/GetEventList', {...params})
}

// 获取当前用户所有设备的展示字段
export const getDeviceShowProper = (params) => {
    return post('/user/Jikong/getDeviceInfo', {...params})
}

// 登录
export const loginUser = (params) => {
    return post('/user/Jikong/LoginWeb', {...params})
}

// 设备录入页面功能
export const getKaiLuDeviceList = (params) => {
    return post('/user/Jikong/getKaiLuDeviceList', {...params})
}

// 设备录入页面功能
export const getAdminKailulist = (params) => {
    return post('/user/Jikong/getKailulist', {...params})
}


export const getDownholeDeviceList = (params) => {
    return post('/user/Jikong/getJinxiaDeviceList', {...params})
}

export const getMileageDeviceList = (params) => {
    return post('/user/Jikong/getAllLcz', {...params})
}


// 设备插入
export const InsertKaiLu = (params) => {
    return post('/user/Jikong/InsertKaiLu', {...params})
}

// 新型设备插入
export const insertNewDevices = (params) => {
    return post('/mdz/addDevices', {...params})
}

// 新型设备更新
export const updateNewDevices = (params) => {
    return post('/mdz/updateDevices', {...params})
}



// 设备插入
export const InsertLCZ = (params) => {
    return post('/user/Jikong/InsertLCZ', {...params})
}


// 设备插入
export const InsertJinxia = (params) => {
    return post('/user/Jikong/InsertJinxia', {...params})
}

// 设备更新
export const UpdateKl = (params) => {
    return post('/user/Jikong/UpdateKl', {...params})
}

// 设备更新
export const UpdateJinxia = (params) => {
    return post('/user/Jikong/UpdateJinxia', {...params})
}

// 设备更新
export const UpdateLCZ = (params) => {
    return post('/user/Jikong/UpdateLCZ', {...params})
}

// 新的获取设备列表
export const getRefactorAllList = (params) => {
    // return {
    //     success: true,
    //     detail: [
    //         {
    //             faulttype: '报警',
    //             devicename: 'aze',
    //             utime: '123',
    //             nickname: 'aze',
    //             resolve: 0,
    //         },
    //         {
    //             faulttype: '正常',
    //             devicename: 'aze',
    //             utime: '123',
    //             nickname: 'aze',
    //             resolve: 1,
    //         },
    //         {
    //             faulttype: '报警',
    //             devicename: 'aze',
    //             utime: '123',
    //             nickname: 'aze',
    //             resolve: 0,
    //         }
    //     ],
    // };
    return post('/mdz/getData', {...params})
}


export const resolveRefactorDevice = (params) => {
    return post('/mdz/resolveData', {...params})
}


// 获取地图左上和右侧
export const getRefactorMapData = (params) => {
    return post('/mdz/getMapData', {...params})
}


// 左侧列表开放设备
export const insertSupportDevice = (params) => {
    return post('/user/Jikong/insertSupportDevice', {...params})
}


// 新的删除新的记录数据
export const deleteRefactorData = (params) => {
    return post('/mdz/deleteData', {...params})
}

// 左侧列表获取开放设备
export const getLeftSupportDevice = async (params) => {
    //     "jx": 0,  井下
    //     "cz": 0,  手持
    //     "kl": 1,  开路
    //     "sc": 0,  手持
    //     "lcz": 0, 里程桩
    //     "ds": 0,  点式
    //     "yt": 0   云台
    const res = await post('/user/Jikong/getLeftSupportDevice', {...params});
    if (!res.detail) {
        await insertSupportDevice({userId: params.userId});
    }
    return post('/user/Jikong/getLeftSupportDevice', {...params});
}

// 获取井下设备所有设备的字段展示情况
export const getAllJinxiaDuopeizhi = (params) => {
    return post('/user/Jikong/getAllJinxiaDuopeizhi', {...params})
}

// 获取全部用户列表数据
export const getAllUser = (params) => {
    return post('/user/JikongAlert/getAllUser', {...params})
}

// 获取井下地图标点
export const getJinXiaMapList = (params) => {
    return post('/user/settings/getJinXiaMapList', {...params})
}

// 获取里程桩地图标点
export const getLczMapList = (params) => {
    return post('/user/settings/getLczMapList', {...params})
}


// 获取井下设备列表
export const getJinxiaDeviceList = (params) => {
    return post('/user/settings/getJinxiaDeviceList', {...params})
}


// 获取里程桩设备列表
export const getLczDeviceList = (params) => {
    return post('/user/settings/getLczDeviceList', {...params})
}

export const StatusMap = {
    Warn: '故障',
    Error: '报警',
    Normal: '正常',
}

// 获取里程桩设备列表
export const getLczMapBaojingList = (params) => {
    return post('/user/settings/getLczMapBaojingList', {...params})
}

// 获取井下设备报警故障右侧列表
export const getJinxiaMapBaojingList = (params) => {
    return post('/user/settings/getJinxiaMapBaojingList', {...params})
}

// 获取所有的二维码列表
export const getAllQrCode = (params) => {
    return post('/user/settings/getAllQrCode', {...params})
}

// 获取所有的二维码列表
export const updateQrCode = (params) => {
    return post('/user/settings/updateQrCode', {...params})
}

// 上传PDF
export const uploadPdfFile = (params) => {
    return post('/user/upload31', params)
}

// 删除里程桩设备api
export const deleteMillageApi = (params) => {
    return post('/user/JikongSave/dellichenzhuangshebei', params)
}

// 删除埋地桩设备api
export const deleteGroundApi = (params) => {
    return post('/mdz/delDevices', params)
}




// 删除井下设备api
export const deleteDownholeApi = (params) => {
    return post('/user/JikongSave/deljingxiashebei', params)
}

// 删除开路设备api
export const deleteOpenApi = (params) => {
    return post('/user/JikongSave/delkailushebei', params)
}

// 埋地桩的数据获取接口
export const getGroundDeviceList = (params) => {
    return post('/mdz/getDeviceList', params)
}

