import {getEventById, getDeviceShowProper} from "@/api/apiHandler";
import {isWarnMsg, isFaultMsg, isMileageWarnMsg, isMileageFaultMsg} from "@/utils/tool";
import {mileageFaultMapString} from "../../utils/tool";
import {getGroundDeviceList, getJinXiaMapList, getLczMapList, getRefactorMapData} from "../../api/apiHandler";


const default_field = ({concentration, speed, wendu}) => [
    {
        name: '浓度',
        value: concentration,
    },
    {
        name: '速度',
        value: speed || 0,
    },
    {
        name: '温度',
        value: wendu || 0,
    }
];
const open_field = ({guangqiang, statusId, concentration}) => [
    {
        name: '光强',
        value: guangqiang,
    },
    {
        name: '状态',
        value: statusId || 0,
    },
    {
        name: '浓度',
        value: concentration || 0,
    }
];
const hand_field = ({guangqiang, statusId, wendu}) => [
    {
        name: '光强',
        value: guangqiang,
    },
    {
        name: '状态',
        value: statusId || 0,
    },
    {
        name: '温度',
        value: wendu || 0,
    }
];

const moduleDevice = {
    state: {
        //    设备列表
        devices: [],
        mileageDevices: [],
        // 除了井上设备的设备列表
        newDevices: [],
        filterType: [],
        // 监控中心页面下的右侧报警列表
        notificationTab: PageTypeEnum.DOWNHOLE,
        monitorEventNum: {
            chezaizhengchang: 0,
            chezaiguzhang: 0,
            chezaibaojing: 0,
            shouchizhengchang: 0,
            shouchiguzhang: 0,
            shouchibaojing: 0,
            kailuzhengchang: 0,
            kailuguzhang: 0,
            kailubaojing: 0,
            lichengzhuangzhengchang: 0,
            lichengzhuangguzhang: 0,
            lichengzhuangbaojing: 0,
        },
        refactorEventNum: {},
        deviceTrackPoint: {},
        // 设备列表展示字段
        deviceShowProper: {},
    },

    getters: {
        devices: state => {
            return state.devices;
        },
        newDevices: state => {
            return state.newDevices;
        },
        monitorEventNum: state => {
            return state.monitorEventNum;
        },
        refactorEventNum: state => {
            return state.refactorEventNum;
        },
        deviceTrackPoint(state) {
            return state.deviceTrackPoint;
        },
        deviceShowProper(state) {
            return state.deviceShowProper;
        },
    },

    mutations: { //逻辑处理，但Mutation 必须是同步函数
        updateDeviceStatus(state, deviceList) {
            const page = state.filterType[0];
            if (page === PageTypeEnum.MONITOR) {
                const showDevice = this.state.user.routes.map((item) => {
                    if (item.show) {
                        return item.type;
                    }
                    return false;
                }).filter(Boolean);
                if (!(showDevice.includes(PageTypeEnum.DOWNHOLE))) {
                    deviceList = [];
                }
            }
            state.devices = deviceList;
        },

        updateMileageDeviceStatus(state, deviceList) {
            const page = state.filterType[0];
            if (page === PageTypeEnum.MONITOR) {
                const showDevice = this.state.user.routes.map((item) => {
                    if (item.show) {
                        return item.type;
                    }
                    return false;
                }).filter(Boolean);
                if (!(showDevice.includes(PageTypeEnum.GROUND))) {
                    deviceList = [];
                }
            }
            state.mileageDevices = deviceList;
        },

        // 设置车载轨迹
        setDeviceTrackPoint(state, {id, data}) {
            state.deviceTrackPoint[id] = data;
        },

        clearDeviceTrackPoint(state) {
            state.deviceTrackPoint = {};
        },

        setNewDeviceStatus(state, deviceList) {
            const page = state.filterType[0];
            if (page === PageTypeEnum.MONITOR) {
                const showDevice = this.state.user.routes.map((item) => {
                    if (item.show) {
                        return item.type;
                    }
                    return false;
                }).filter(Boolean);
                deviceList = deviceList.filter((item) => {
                    return showDevice.includes(item.deviceType)
                });
            }
            // 更新除了井上设备的设备列表
            state.newDevices = deviceList;
        },

        setMonitorEventNum(state, data) {
            state.monitorEventNum = data;
        },


        // 新的埋地桩以及以后得设备都这么弄
        setRefactorEventNum(state, data) {
            state.refactorEventNum = data;
        },


        updateFilterType(state, filterTypes) {
            state.filterType = filterTypes;
        },

        setNotificationTab(state, data) {
            state.notificationTab = data;
        },
        setDeviceShowProper(state, data) {
            state.deviceShowProper = data;
        },
    },

    actions: {

        async updateDeviceShowProper({rootState, state}) {
            const userId = rootState.user.userId;
            const res = await getDeviceShowProper({
                userId,
            });
            window.globalCustomData.deviceProper = {};
            res.forEach((item) => {
                window.globalCustomData.deviceProper[item.deviceName] = item;
                state.deviceShowProper[item.deviceName] = item;
            });
        },

        // 更新所有设备的值
        async updateNewDeviceStatus({commit, rootState}) {
            const userId = rootState.user.userId;
            const eventRes = await getEventById({id: userId});
            if (!eventRes.success) {
                return;
            }
            const formatDeviceList = [];
            const pageType = rootState.device.filterType[0];
            eventRes.detail.forEach((item) => {
                const {
                    type: deviceType,
                    statusId: deviceStatus,
                    latitude: lat,
                    longitude: lng,
                    deviceName,
                    id,
                } = item;

                let fieldList = default_field(item);
                if ([pageType, item.type].includes(PageTypeEnum.OPEN)) {
                    fieldList = open_field(item);
                }
                if ([pageType, item.type].includes(PageTypeEnum.HAND)) {
                    fieldList = hand_field(item);
                }
                fieldList.forEach((item) => {
                    if (item.name === '浓度') {
                        const currentUnit = this.getters["configs/unitInfo"];
                        item.value = `${currentUnit.transform(item.value).value}${currentUnit.text}`;
                    }
                });
                formatDeviceList.push({
                    fault: item.fault,
                    name: deviceName,
                    fieldList,
                    deviceType,
                    status: deviceStatus,
                    position: [lng, lat],
                    id,
                });
            });
            commit('setNewDeviceStatus', formatDeviceList);
            commit('setMonitorEventNum', {
                ...eventRes,
            });
        },

        //Action 类似于 mutation,Action 提交的是 mutation，而不是直接变更状态;Action 可以包含任意异步操作.
        updateDeviceStatus(context) {
            getJinXiaMapList({
                userId: context.rootState.user.userId,
            }).then(res => {
                // console.log(res)
                let formatDeviceList = [];
                const positions = res;
                if (Array.isArray(positions)) {
                    positions.map((value) => {
                        // 设备状态的格式化
                        let deviceStatus = NORMAL;
                        if (isFaultMsg(value.fault)) {
                            deviceStatus = WARN;
                        }
                        if (isWarnMsg(value.fault)) {
                            deviceStatus = ERROR
                        }
                        // 设备的位置信息
                        let lat = value.latitude;
                        let lng = value.longitude;
                        const fieldList = [
                            {
                                name: '浓度',
                                value: value.ch4
                            },
                            {
                                name: '信号强度',
                                value: value.signal
                            },
                            {
                                name: '电量',
                                value: `${Number(value.power) / 100}V`
                            },
                            {
                                name: '进气口压强',
                                value: value.entrance_pressure,
                            },
                            {
                                name: '出气口压强',
                                value: value.exit_pressure,
                            }
                        ].filter(Boolean);

                        // 井下设备格式化
                        formatDeviceList.push({
                            fault: value.fault,
                            name: value.nickname,
                            fieldList,
                            deviceType: DOWNHOLE,
                            status: deviceStatus,
                            position: [lng, lat],
                            id: value.id
                        })
                    });
                    context.commit('updateDeviceStatus', formatDeviceList);
                }
            })
        },
        //Action 类似于 mutation,Action 提交的是 mutation，而不是直接变更状态;Action 可以包含任意异步操作.
        updateMileageDeviceStatus(context) {
            getLczMapList({userId: context.rootState.user.userId}).then(res => {
                // console.log(res)
                let formatDeviceList = [];
                const positions = res;
                if (Array.isArray(positions)) {
                    positions.map((value) => {
                        // 设备状态的格式化
                        let deviceStatus = NORMAL;
                        if (isMileageFaultMsg(value)) {
                            deviceStatus = WARN;
                        }
                        if (isMileageWarnMsg(value)) {
                            deviceStatus = ERROR
                        }
                        // 设备的位置信息
                        let lat = value.latitude;
                        let lng = value.longitude;
                        const fieldList = [
                            {
                                name: '浓度',
                                value: value.ch4
                            },
                            {
                                name: '信号强度',
                                value: value.signal
                            },
                            {
                                name: '电量',
                                value: `${Number(value.power) / 100}V`
                            },
                        ].filter(Boolean);
                        if (deviceStatus === ERROR) {
                            fieldList.push({
                                name: '报警信息',
                                value: mileageFaultMapString(value.fault),
                            });
                        }
                        if (deviceStatus === WARN) {
                            fieldList.push({
                                name: '故障信息',
                                value: mileageFaultMapString(value.fault),
                            });
                        }
                        // 里程碑设备格式化
                        formatDeviceList.push({
                            fault: value.fault,
                            name: value.nickname,
                            fieldList,
                            deviceType: PageTypeEnum.MILEAGE,
                            status: deviceStatus,
                            position: [lng, lat],
                            id: value.id
                        })
                    });
                    context.commit('updateMileageDeviceStatus', formatDeviceList);
                }
            })
        },
        updateGroundDeviceStatus(context) {
            getGroundDeviceList({
                isfenye: '不分页',
                devicetype: PageTypeEnum.GROUND,
                username: context.rootState.user.username,
                nickname: '',
                pageSize: 15,
            }).then(res => {
                const formatDeviceList = [];
                const positions = res.detail;
                if (Array.isArray(positions)) {
                    positions.map((value) => {
                        const {faulttype, faultvalue} = value;
                        // 设备状态的格式化
                        let deviceStatus = NORMAL;
                        if (faulttype === '故障') {
                            deviceStatus = WARN;
                        }
                        if (faulttype === '报警') {
                            deviceStatus = ERROR
                        }
                        // 设备的位置信息
                        const lat = value.latitude;
                        const lng = value.longitude;
                        const fieldList = [
                            {
                                name: '浓度',
                                value: value.ch4
                            },
                            {
                                name: '信号质量',
                                value: value.rsrp
                            },
                            {
                                name: '电量',
                                value: value.power,
                            },
                        ].filter(Boolean);
                        if (deviceStatus === ERROR) {
                            fieldList.push({
                                name: '报警信息',
                                value: faultvalue,
                            });
                        }
                        if (deviceStatus === WARN) {
                            fieldList.push({
                                name: '故障信息',
                                value: faultvalue,
                            });
                        }
                        formatDeviceList.push({
                            fault: value.fault,
                            name: value.nickname,
                            fieldList,
                            deviceType: PageTypeEnum.GROUND,
                            status: deviceStatus,
                            position: [lng, lat],
                            id: value.id
                        })
                    });
                    context.commit('setNewDeviceStatus', formatDeviceList);
                }
            })
            getRefactorMapData({username: context.rootState.user.username}).then(res => {
                context.commit('setRefactorEventNum', {
                    ...res,
                });
            })
        },
        updateFilterType(context, filterTypes) {
            context.commit('updateFilterType', filterTypes);
        },
    }
}

export default moduleDevice;
