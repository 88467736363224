<template>
  <div class="device-container">
    <div class="search-wrap">
      <div class="button-wrap">
        <div class="button"
             v-on:click="addPdf">
          <span>上传PDF</span>
        </div>
      </div>
    </div>

    <div class="table-container">
      <TableComponent :columns="columns" :rows="qrList" :checkbox="false"
                      :total="pagination.total" :current-page="pagination.currentPage"
                      :usePagination="false"
                      :page-size="pagination.pageSize"/>
    </div>
    <Modal
        :visible="uploadShow"
        class="upload-modal"
        :on-close-trigger="() => {this.uploadShow = false}">
      <template v-slot:header>
        <h3>上传PDF</h3>
      </template>
      <template v-slot:body>
        <div style="width: 100%; height: 100%">
          <input type="file" id="pdfFile">
        </div>
      </template>
      <template v-slot:footer>
        <div class="button-wrap">
          <div class="button prefer" v-on:click="uploadFile">
            <span>确认上传</span>
          </div>
        </div>
      </template>
    </Modal>
    <el-dialog
        :visible.sync="dialogVisible"
        append-to-body
        width="30%"
    >
      <div style="width: 100%; height: 100%">
        <img :src="curQrImg" alt="" style="width: 100%; height: 100%"/>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TableComponent from "@/components/Table";
import {getAllQrCode, updateQrCode, uploadPdfFile} from "../../api/apiHandler";
import Modal from "@/components/Modal";
import Vue from "vue";

export default {
  name: "DeviceList",
  components: {
    Modal,
    TableComponent
  },
  data: function () {
    return {
      // 基础数据信息
      columns: [
        {
          label: '二维码名称',
          key: 'qrName',
          width: '40%',
        },
        {
          label: '二维码状态',
          key: 'qrStatus',
          width: '40%',
        },
        {
          label: '预览',
          key: 'operator',
          width: '5%',
          // eslint-disable-next-line no-unused-vars
          render: (value, row) => {
            return Vue.component('edit', {
              render: (h) => {
                return h('span', {
                  on: {
                    click: () => {
                      this.showDialog(row)
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#00aaff',
                  },
                }, ['查看二维码'], '');
              }
            });
          }
        },
        {
          label: '操作',
          key: 'operator',
          width: '5%',
          // eslint-disable-next-line no-unused-vars
          render: (value, row) => {
            return Vue.component('edit', {
              render: (h) => {
                return h('span', {
                  on: {
                    click: () => {
                      this.switchQrStatus(row)
                    },
                  },
                  style: {
                    cursor: 'pointer',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: '#00aaff',
                  },
                }, [row.qrStatus === '正常' ? '置为失效' : '置为正常'], '');
              }
            });
          }
        },
      ],
      timer: null,
      // 设备列表
      qrList: [],
      pagination: {
        currentPage: 0,
        pageSize: 10,
        total: 10,
      },
      dialogVisible: false,
      uploadShow: false,
      uploadLoading: false,
      curQrImg: '',
    };
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    async uploadFile() {
      if (this.uploadLoading) {
        return;
      }
      this.uploadLoading = true;
      try {
        const inputFile = document.querySelector('#pdfFile')
        const file = inputFile?.files[0];
        if (!file || !inputFile) {
          alert('请选择文件');
          return;
        }
        const formData = new FormData();
        formData.append('file', file);
        formData.append('qrName', file.name);
        const res = await uploadPdfFile(formData);
        this.uploadShow = false;
        inputFile.value = '';
        if (!res || res !== '上传成功') {
          alert('上传失败,可能存在重复PDF文件命名');
          return;
        }
        await this.$store.dispatch('toast/showToast', { message: res })
      } catch (e) {
        console.log('error')
      } finally {
        this.uploadLoading = false;
        setTimeout(() => {
          this.init();
        }, 500)
      }
    },
    addPdf() {
      this.uploadShow = true;
    },
    async init() {
      this.qrList = await getAllQrCode() || [];
    },
    showDialog(row) {
      this.dialogVisible = true;
      this.curQrImg = `${row.qrCodeUrl}`;
    },
    switchQrStatus(row) {
      updateQrCode({
        qrName: row.qrName,
        qrStatus: row.qrStatus === '正常' ? '失效' : '正常',
      }).then((res) => {
        this.init();
      });
    },
  },

  watch: {},

  computed: {},
}
</script>

<style scoped lang="less">
@import "../../styles/common";

.device-container {
  position: relative;
  z-index: 10;
  width: 100%;
  pointer-events: visible;
  font-size: 0.16rem;
  color: white;

  .search-wrap {
    padding-top: calc(0.26rem + 0.58rem);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    .input-wrap {
      width: 2.0rem;
      margin-right: 0.1rem;
      height: @navButtonSize;
      background-image: url("../../assets/img/search.svg");
      background-repeat: no-repeat;
      padding-left: 0.3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      background-position-y: center;
      border-bottom: 1px solid rgba(175, 176, 176, 1);

      input {
        background: transparent;
        border: none;
        outline: none;
        color: white;

        &::-webkit-calendar-picker-indicator {
          margin-top: 4px;
          cursor: pointer;
          filter: invert(1);
        }

        &::-webkit-input-placeholder {
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }

    .button-wrap {
      width: 100%;
      .button;
      margin-left: 10px;
      margin-bottom: @padding;
    }
  }

  .table-container {
    width: 100%;
  }

}
::v-deep {
  .table-wrap .table-body {
    max-height: 8.1rem;
  }
}

.upload-modal {
  .button-wrap {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    padding: 0 @padding @padding;
    .button;
  }
}

</style>
