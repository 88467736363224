import { render, staticRenderFns } from "./RefactorSmallNotification.vue?vue&type=template&id=53c9be58&scoped=true&"
import script from "./RefactorSmallNotification.vue?vue&type=script&lang=js&"
export * from "./RefactorSmallNotification.vue?vue&type=script&lang=js&"
import style0 from "./RefactorSmallNotification.vue?vue&type=style&index=0&id=53c9be58&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53c9be58",
  null
  
)

export default component.exports