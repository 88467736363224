import {request} from "@/utils/http";
import {getJinxiaDeviceList} from "../../../api/apiHandler";

const moduleDownholeList = {
    namespaced: true,
    state: {
        list: [],
        total: 0,
        pageSize: 0,
        currentPage: 0,
    },
    mutations: {
        updateData: function (state, {currentPage, list, total, pageSize}) {
            state.currentPage = currentPage;
            state.list = list;
            state.total = total;
            state.pageSize = pageSize;
        }
    },
    actions: {
        fetchPage: function(context, { page, query }) {
            if(!page) {
                page = context.state.currentPage||1
            }
            getJinxiaDeviceList({userId: context.rootState.user.userId, currentPage: page, devicename: query.devicename }).then(res => {
                let formatList = [];
                res?.detail?.map(item => {
                    formatList.push({
                        ...item,
                        id: item.id,
                        deviceName: item.devicename,
                        nickName: item.nickname,
                        alerts: item.alerts,
                        fault: item.fault,
                        concentration: item.ch4
                    })
                });
                context.commit('updateData', {
                    currentPage: res.currentPage,
                    currentQuery: query,
                    total: res.allSize,
                    pageSize: 10,
                    list: formatList
                });
            })
        }

    }

}

export default moduleDownholeList;
